<template>
  <a-drawer
    wrapClassName="organization-structure-drawer"
    :width="'62vw'"
    title="组织架构人员查看"
    :visible="showDrawer"
    @update:visible="(val) => $emit('update:visible', val)"
  >
    <div class="content">
      <div class="returnText" v-show="data.length >= 2">
        <span @click="returnToPreLevel">返回上一级</span>
      </div>
      <template
        v-for="{
          avatar,
          fullName,
          nameInitials,
          email,
          orgThreeTwo,
          hierarchy,
          userId,
          lmGlobalId,
          children = [],
          isShowOrg
        } in data"
        :key="userId"
      >
        <MemberCard
          :class="{
            card: true,
            disabled: !userId,
          }"
          :avatar="avatar"
          :name="fullName"
          :nameInitials = "nameInitials"
          :email="email"
          :org="orgThreeTwo"
          :hierarchy="hierarchy"
          :ref="`member-card-${userId}`"
          :isShowOrg="isShowOrg"
          @click="getOrg({ userId, lmGlobalId })"
        />
        <div v-if="children?.length" class="members">
          <div class="title">
            向{{ fullName }}报告的LM：<span class="num"
              >{{ children.length }}人</span
            >
          </div>
          <div class="cards">
            <MemberCard
              :class="{
                card: true,
                disabled: !member.userId,
              }"
              v-for="member in children"
              :key="member.userId"
              :avatar="member.avatar"
              :name="member.fullName"
              :nameInitials = "member.nameInitials"
              :email="member.email"
              :org="member.orgThreeTwo"
              :hierarchy="member.hierarchy"
              :isShowOrg="member.isShowOrg"
              @click="
                getOrg({
                  userId: member.userId,
                  lmGlobalId: member.lmGlobalId,
                })
              "
            />
          </div>
        </div>
      </template>
    </div>
  </a-drawer>
</template>

<script>
import MemberCard from "@/components/MemberCard.vue";
import { getOrg } from "@/api/modules/Home/leadershipManagement.js";
import { message } from "ant-design-vue";
export default {
  components: { MemberCard },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    personData: {
      type: Object
    }
  },
  data() {
    return {
      params: {
        userId: "",
        lmGlobalId: "",
      },
      showDrawer: this.visible,
      loading: false,
      data: [],
      isShowOrg: false
    };
  },
  watch: {
    visible(val) {
      this.showDrawer = val;
    },
  },
  methods: {
    // 返回上一级层级
    returnToPreLevel() {
      if(this.data.length < 2) return
      const userId = this.data[this.data.length - 2].userId
      const lmGlobalId = this.data[this.data.length - 2].lmGlobalId
      this.getOrg({userId: userId, lmGlobalId: lmGlobalId})
    },

    getOrg(params = this.params) {
      this.loading = true;
      if(params.lmGlobalId === null){
        params.lmGlobalId = ""
      }
      getOrg(params)
        .then((res) => {
          if (!res.data) {
            message.error(res.msg)
            return
          }
          if (res.data?.length) {
            let data = [];
            const { parent = [], ...own } = res.data[0];
            own.avatar = own.photo || "#255C6F";
            own.children = own.children.map((item) => ({
              ...item,
              avatar: item.photo || "#546FE5",
            }));
            data = data
              .concat(
                parent.sort((a, b) => a.hierarchy.localeCompare(b.hierarchy))
              )
              .map((item) => ({
                ...item,
                avatar: item.photo || "#1E5087",
              }));
            // 取fullName首字符，显示在头像
            own.children = own.children.map(item => this.handleProfile(item))
            own.children = own.children.map((item) => {
              item.isShowOrg = item.roleKey.indexOf('COO') === -1
              return item
            })
            data.push(own);
            data.map((item => this.handleProfile(item)))
            data.map((item) => {
              item.isShowOrg = item.roleKey.indexOf('COO') === -1
              return item
            })
            this.data = data;
            this.$nextTick(() => {
              let ownCard = this.$refs[`member-card-${params.userId}`] || null;
              if (ownCard?.$el)
                ownCard?.$el.scrollIntoView({ block: "center" });
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleProfile(item){
      const nameList = item.fullName.split(',')
      item.nameInitials = nameList[1]?.trim()[0] + nameList[0][0]
      return item
    },
  },
  mounted() {
    this.params.userId = this.personData.userId ? this.personData.userId : "";
    this.params.lmGlobalId = this.personData.lmGlobalId ? this.personData.lmGlobalId : "";
    this.getOrg();
  },
};
</script>

<style lang="less">
.organization-structure-drawer {
  .ant-drawer-content-wrapper {
    //width: 1306px !important;
    .content {
      min-height: 40vh;
      max-height: 85vh;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .returnText {
        position: absolute;
        right: 7%;
        color: #3182CE;
        cursor: pointer;
      }
      .card {
        margin: 0 20px 20px 0;
        cursor: pointer;
        &.disabled {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
      .members {
        background: #f7fafc;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
        .title {
          padding: 20px 0 0 20px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
          color: #2d3748;
          .num {
            color: #12abdb;
          }
        }
        .cards {
          margin-top: 6px;
          display: flex;
          flex-wrap: wrap;
          .card {
            box-shadow: unset;
          }
        }
      }
    }
  }
}
</style>
