<template>
  <div
    ref="chart"
    style="width: 100%; height: 100%"
    v-resize="adapterScreen"
  ></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "LeadershipLevelBarChart",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
    },
    setOption() {
      this.chart.setOption({
        legend: {
          orient: "vertical",
          icon: "circle",
          itemWidth: 10,
          itemHeight: 10,
          right: 0,
          data: ["男", "女", "未知"],
        },
        textStyle: {
          fontFamily: "PingFang SC",
          fontSize: 11,
        },
        grid: {
          containLabel: true,
        },
        // tooltip.trigger设置为none,可以什么都不触发。
        series: [
          {
            type: "pie",
            radius: [0, "65%"],
            label: {
              alignTo: "labelLine",
              formatter: "{c}人\n{d}%",
            },
            labelLine: {
              length: 5,
            },
            data: [
              { value: this.data.Male, name: "男" },
              { value: this.data.Female, name: "女" },
              { value: this.data.Unknown, name: "未知" },
            ],
            color: ["#5296D2", "#EFA175", "#58B99E"],
            silent: true,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
    // 适配
    adapterScreen() {
      let adapterFont = (this.$refs.chart.offsetWidth / 100) * 3;

      const adpOption = {
        legend: {
          itemWidth: adapterFont / 2,
          itemHeight: adapterFont / 2,
          itemGap: adapterFont,
          textStyle: { fontSize: adapterFont },
        },
        series: [
          {
            label: {
              fontSize: adapterFont,
            },
            textStyle: { fontSize: adapterFont },
            emphasis: {
              itemStyle: {
                shadowBlur: adapterFont / 2,
                shadowOffsetX: 0,
              },
            },
          },
        ],
      };
      this.chart.setOption(adpOption);
      this.chart.resize();
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
  },
  mounted() {
    this.init();
    this.setOption();
    this.adapterScreen();
  },
};
</script>
