<template>
  <div class="history-layer">
    <div ref="returnBtn">
      <span class="link underline returnArea" @click="$emit('return')">
        <LeftOutlined class="return-icon" />返回上一级</span
      >
    </div>
    <div class="header mt24 mb24">
      <UserCard :user="data" :isPoolTab="isPoolTab" :userInfo="userInfo"/>
      <a-button type="primary" ghost @click="handleExport" v-auth="['system:leadershipManagement:exportInfo']">
        <UploadOutlined />
        导出
      </a-button>
    </div>
    <a-table
      rowKey="id"
      :columns="table.columns"
      :data-source="table.data"
      :scroll="{ x: 2000,y: tableHeight,scrollToFirstRowOnChange: true, }"
      :pagination="pagination"
    />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, onUnmounted, nextTick } from "vue";
import { LeftOutlined, UploadOutlined } from "@ant-design/icons-vue";
import UserCard from "./UserCard.vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {downloadFile, useTableHeight} from "@/utils/common";
import {
  totalLeadershipRender,
  leadershipRender,
  tooltipRender,
} from "../LeadershipTableRender";
export default defineComponent({
  components: { LeftOutlined, UserCard, UploadOutlined },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isPoolTab: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { $api } = useGlobalPropertyHook();
    const returnBtn = ref();
    // 按页签进行动态新增的三列表格字段
    const newColums = props.isPoolTab
      ? [
          {
            title: "年/季度",
            width: 120,
            dataIndex: "yearAndQuarter",
            key: "yearAndQuarter",
            ellipsis: true,
          },
          {
            title: "新LM",
            width: 120,
            dataIndex: "isNew",
            key: "isNew",
            ellipsis: true,
            customRender: ({ text }) =>
              Number(text) === 1 ? "是" : text === null ? "" : "否",
          },
          {
            title: "关键人才",
            width: 120,
            dataIndex: "isKeyTalent",
            key: "isKeyTalent",
            ellipsis: true,
            customRender: ({ text }) =>
              Number(text) === 1 ? "是" : text === null ? "" : "否",
          },
          {
            title: "直接下属人数",
            width: 150,
            dataIndex: "subordinate",
            key: "subordinate",
            ellipsis: true,
          },
        ]
      : [];
    const table = reactive({
      columns: [
        // {
        //   title: "记录时间",
        //   width: 100,
        //   dataIndex: "recordingTime",
        //   key: "recordingTime",
        //   align: "center",
        // },
        {
          title: "操作时间",
          width: 150,
          dataIndex: "operationTime",
          key: "operationTime",
          ellipsis: true,
        },
        {
          title: "最近沟通时间",
          width: 130,
          dataIndex: "communicationTime",
          key: "communicationTime",
          ellipsis: true,
        },
        ...newColums,
        {
          title: "识别动机",
          width: 150,
          dataIndex: "motivation",
          key: "motivation",
          ellipsis: true,
          customRender: tooltipRender,
        },
        {
          title: "动机备注",
          width: 120,
          dataIndex: "motivationRemarks",
          key: "motivationRemarks",
          ellipsis: true,
          customRender: tooltipRender,
        },
        {
          title: "领导意愿",
          width: 120,
          dataIndex: "willingness",
          key: "willingness",
          customRender: leadershipRender,
          ellipsis: true,
        },
        {
          title: "以人为本",
          width: 120,
          dataIndex: "humanized",
          key: "humanized",
          customRender: leadershipRender,
          ellipsis: true,
        },
        {
          title: "有效沟通",
          width: 120,
          dataIndex: "communication",
          key: "communication",
          ellipsis: true,
          customRender: leadershipRender,
        },
        {
          title: "协作",
          width: 100,
          dataIndex: "collaboration",
          key: "collaboration",
          ellipsis: true,
          customRender: leadershipRender,
        },
        {
          title: "领导技能",
          width: 120,
          dataIndex: "skill",
          key: "skill",
          ellipsis: true,
          customRender: leadershipRender,
        },
        {
          title: "领导精力",
          width: 120,
          dataIndex: "energy",
          key: "energy",
          ellipsis: true,
          customRender: leadershipRender,
        },
        {
          title: "敬业投入",
          width: 120,
          dataIndex: "inputs",
          key: "inputs",
          ellipsis: true,
          customRender: leadershipRender,
        },
        {
          title: "总计",
          width: 100,
          dataIndex: "total",
          key: "total",
          ellipsis: true,
          customRender: totalLeadershipRender,
        },
        {
          title: "备注",
          width: 150,
          dataIndex: "remarks",
          key: "remarks",
          ellipsis: true,
          customRender: tooltipRender,
        },
        {
          title: "行动",
          width: 100,
          dataIndex: "action",
          key: "action",
          ellipsis: true,
          customRender: tooltipRender,
        },
        {
          title: "DISC",
          width: 100,
          dataIndex: "disc",
          key: "disc",
          ellipsis: true,
          customRender: tooltipRender,
        },
        {
          title: "入职年限",
          width: 120,
          dataIndex: "years",
          key: "years",
          ellipsis: true,
        },
        {
          title: "职级",
          width: 100,
          dataIndex: "psGroup",
          key: "psGroup",
          ellipsis: true,
        },
        {
          title: "Team",
          width: 120,
          dataIndex: "orgThreeTwo",
          key: "orgThreeTwo",
          ellipsis: true,
        },
        {
          title: "Sub Team",
          width: 170,
          dataIndex: "orgFourTwo",
          key: "orgThreeTwo",
          ellipsis: true,
        },
        {
          title: "Onshore/RDC",
          width: 150,
          dataIndex: "orgFiveOne",
          key: "orgFiveOne",
          ellipsis: true,
        },
        {
          title: "组织层级",
          width: 120,
          dataIndex: "hierarchy",
          key: "hierarchy",
          ellipsis: true,
        },
      ],
      data: [],
    });

    // 获取table 长度@todo
    const tableHeight = ref(0);
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };
    const getData = () => {
      $api.getLeadershipDetailHistory({
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          userId: props.data.userId,
          isPoolTab: props.isPoolTab,
          quarter: props.data.quarter,
          year: props.data.year
        })
        .then((res) => {
          table.data = props.isPoolTab ? res.data.content : res.data.resource;
          pagination.current = props.isPoolTab ? res.data.pageable.page + 1 : res.data.pageIndex + 1;
          pagination.pageSize = props.isPoolTab ? res.data.pageable.size : res.data.pageSize;
          pagination.total = res.data.total;
        });
    };
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getData();
      },
      onShowSizeChange: (_, size) => {
        pagination.current = 1;
        pagination.pageSize = size;
        getData();
      },
    });
    const handleExport = () => {
      $api
        .exportLeadershipDetailHistory({
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          userId: props.data.userId,
          isPool: !props.isPoolTab ? '1' : '0',
          quarter: props.data.quarter,
          year: props.data.year
        })
        .then((res) => {
          downloadFile(res.file,
              props.isPoolTab ? "健康度明细 —— 个人记录.xls" : "储备管理 —— 个人记录.xls");
        });
    };
    const userInfo = ref({})
    // 获取历史人员的最新信息（职级、id）
    const getUserInfo = () => {
      $api.getHistoryUserInfo(props.data.userId).then((res) => {
        userInfo.value = res.data
      })
    };
    window.addEventListener("resize", getSize);

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    onMounted(() => {
      returnBtn.value.scrollIntoView();
      getData();
      getUserInfo();
      getSize();
    });
    return {
      pagination,
      returnBtn,
      table,
      handleExport,
      getUserInfo,
      userInfo,
      tableHeight
    };
  },
});
</script>

<style lang="less">
/*.ant-tabs-tabpane-active {
  position: relative !important;
}
.history-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;
  background: #fff;
  z-index: 100;
  .return-icon {
    font-size: 20px;
    margin-right: 8px;
    vertical-align: bottom;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}*/
.history-layer {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .returnArea {
    font-size: 20px;
    font-weight: 400;
  }
}
</style>
