<template>
  <div
    ref="chart"
    style="width: 100%; height: 100%"
    v-resize="adapterScreen"
  ></div>
</template>

<script>
import * as echarts from "echarts";
import { leadershipWeight } from "@/config/leadership";

export default {
  name: "LeadershipWeightRadarChart",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    setVal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fontPie: 0
    }
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
    },
    setOption() {
      this.fontPie = (this.$refs.chart.offsetWidth / 100) * 2.7
      this.chart.setOption({
        tooltip: {
          textStyle: {
            fontSize: this.fontPie / 1.5,
          },
        },
        textStyle: {
          fontFamily: "PingFang SC",
          fontSize: 16,
        },
        legend: {
          orient: "vertical",
          icon: "circle",
          itemWidth: 10,
          itemHeight: 10,
          right: 0,
          data: this.data.map((item) => Object.keys(item)[0]),
          // 总平均分四舍五入，小数点后一位
          formatter: (name) => {
            const index = this.data.findIndex((item) => item[name]);
            if (
              JSON.stringify(this.data[index][name]) !== "{}" &&
              index !== -1
            ) {
              return (
                name +
                "\xa0\xa0\xa0\xa0总平均分:" +
                this.fomatFloat(this.data[index][name].total, 1)?.toFixed(1)
              );
            } else {
              return name;
            }
          },
        },
        radar: {
          indicator: leadershipWeight.map(({ label }, index) => ({
            name: label,
            min: this.minMaxVal.minVal,
            max: this.minMaxVal.maxVal,
            axisLabel: {
              show: index === 0,
              fontSize: this.fontPie / 1.2,
            },
          })),
          // radius: 80,
          center: ["40%", "55%"],
        },
        series: [
          {
            type: "radar",
            color: ["#5296D2", "#EFA175"],
            symbolSize: 5,
            itemStyle: {
              opacity: 0.7,
            },
            lineStyle: {
              opacity: 0.7,
              width: 1.5,
            },
            //
            data: this.data.map((item) => {
              let key = Object.keys(item)[0];
              return {
                // 四舍五入，小数点后一位
                value: leadershipWeight.map(({ name }) => {
                  if (item[key]?.[name]) {
                    return this.fomatFloat(item[key]?.[name], 1)?.toFixed(1);
                  } else {
                    return undefined;
                  }
                }),
                name: key,
              };
            }),
          },
        ],
      });
    },
    // onResize() {
    //   this.chart.resize();
    // },
    // 四舍五入方法
    fomatFloat(src, pos) {
      return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos);
    },
    // 适配
    adapterScreen() {
      this.fontPie = (this.$refs.chart.offsetWidth / 100) * 2.7
      const adpOption = {
        tooltip: {
          textStyle: {
            fontSize: this.fontPie / 1.2,
          },
        },
        legend: {
          itemWidth: this.fontPie / 2.5,
          itemHeight: this.fontPie / 2.5,
          itemGap: this.fontPie / 1.2,
          textStyle: { fontSize: this.fontPie / 1.2 },
        },
        grid: {
          top: "5%",
          bottom: "5%",
          containLabel: true,
        },
        radar: {
          axisName: {
            fontSize: this.fontPie / 1.2,
          },
          name: {
            textStyle: {
              fontSize: "100px",
              // 修饰雷达图文字的颜色
              color: "#3182CE",
            },
          },
          center: ["40%", "55%"],
        },
        series: [
          {
            symbolSize: this.fontPie / 1.2,
            itemStyle: {
              opacity: 0.7,
              fontSize: this.fontPie / 1.2,
            },
          },
        ],
      };
      this.chart.setOption(adpOption);
      this.chart.resize();
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
  },
  computed: {
    // fontPie() {
    //   return (this.$refs.chart.offsetWidth / 100) * 3;
    // },
    minMaxVal() {
      if (this.setVal) {
        return { minVal: 1, maxVal: 5 };
      } else {
        return { minVal: 2, maxVal: 4 };
      }
    },
  },
  mounted() {
    this.init();
    this.setOption();
    this.adapterScreen();
  },
};
</script>
