<template>
  <div ref="chart" style="width: 100%; height: 100%" v-resize="onResize"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "LeadershipSexPieChart",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
    },
    setOption() {
      this.chart.setOption({
        textStyle: {
          fontFamily: "PingFang SC",
          fontSize: 11,
        },
        xAxis: {
          type: "category",
          data: ["待提升", "一般", "优秀"],
        },
        yAxis: {
          type: "value",
          minInterval: 1,
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "3%",
          containLabel: true,
        },
        series: [
          {
            type: "bar",
            barWidth: "45%",
            label: {
              show: true,
              position: "top",
              formatter: ({ value }) => (value ? `${value}人` : ""),
            },
            silent: true,
            data: [
              {
                value: this.data.red,
                itemStyle: {
                  color: "#DC5E4F",
                },
              },
              {
                value: this.data.yellow,
                itemStyle: {
                  color: "#FDCD46",
                },
              },
              {
                value: this.data.green,
                itemStyle: {
                  color: "#58B99E",
                },
              },
            ],
          },
        ],
      });
    },
    onResize() {
      this.chart.resize();
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
  },
  mounted() {
    this.init();
    this.setOption();
  },
};
</script>
