<template>
  <div ref="chart" style="width: 100%; height: 100%" v-resize="onResize"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "LeadershipYearsBarChart",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
    },
    setOption() {
      this.chart.setOption({
        textStyle: {
          fontFamily: "PingFang SC",
          fontSize: 11,
        },
        grid: {
          left: 1,
          right: 3,
          top: 20,
          bottom: 0,
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["0-1年", "1-3年", "3-5年", "5-10年", "10年以上"],
            axisTick: {
              alignWithLabel: true,
            },
            // axisLabel: {
            //   rotate: 30, //文字倾斜角度
            // },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              formatter: (val, key) => {
                if (key % 2 == 0) {
                  return val;
                }
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "60%",
            silent: true,
            label: {
              show: true,
              position: "top",
              formatter: ({ value }) => (value ? `${value}人` : ""),
            },
            data: [
              this.data.lessThanOne,
              this.data.oneThree,
              this.data.threeFive,
              this.data.fiveTen,
              this.data.ten,
            ],
            color: ["#5296D2", "#EFA175"],
          },
        ],
      });
    },
    onResize() {
      this.chart.resize();
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.setOption();
      },
    },
  },
  mounted() {
    this.init();
    this.setOption();
  },
};
</script>
