<template>
  <!--  领导力管理 -->
  <section class="app-container">
    <filter-layout @doReset="resetForm" @doSearch="getHierarchy" ref="filterLayoutRef">
      <template #customform>
        <a-form
            ref="formRef"
            class="form rms-form"
            :model="org"
            layout="vertical"
        >
          <a-form-item
              label="选择团队"
              class="rms-form-item"
          >
            <a-select
                :disabled="org.data.length < 1 || !isCheckSucess"
                v-model:value="org.value"
            >
              <a-select-option value="all">ALL</a-select-option>
              <a-select-option v-for="item in org.data" :key="item" :value="item">{{
                  item
                }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </template>
    </filter-layout>
    <a-tabs class="tabs" v-model:activeKey="tab.value" @change="updateTag">
      <a-tab-pane
        v-for="{ name, title } in tab.config"
        :key="name"
        :tab="title"
      >
        <component
          v-if="
            isCheckSucess && org.data.length && org.value && name === tab.value
          "
          :is="name"
          :orgs="orgStr"
          :switchTab="switchTab"
          v-model:filter="filter"
          :currentQuarter="currentQuarter"
          @getHistoryHeight="getHistoryHeight"
        />
        <div
          class="empty"
          v-if="!(isCheckSucess && org.data.length && org.value)"
        >
          <img src="@/assets/home/home.png" alt="home" />
          <div v-if="isCheckSucess">请在右上角选择团队名称</div>
          <div v-else>{{ checkMsg }}</div>
        </div>
      </a-tab-pane>
<!--      <template #tabBarExtraContent>-->
<!--        <span class="mr16">选择团队:</span>-->
<!--        <a-select-->
<!--          :disabled="org.data.length < 1 || !isCheckSucess"-->
<!--          v-model:value="org.value"-->
<!--          style="width: 240px"-->
<!--          @change="getHierarchy"-->
<!--        >-->
<!--          <a-select-option value="all">ALL</a-select-option>-->
<!--          <a-select-option v-for="item in org.data" :key="item" :value="item">{{-->
<!--            item-->
<!--          }}</a-select-option>-->
<!--        </a-select>-->
<!--      </template>-->
    </a-tabs>
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  provide,
  computed,
  watch,
  ref,
  readonly,
} from "vue";
import HealthKanban from "./HealthKanban.vue";
import HealthDetail from "./HealthDetail.vue";
import PoolManagement from "./PoolManagement.vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useStore } from "vuex";

import {dictionaryOpt, dictionaryOptionsCode} from "@/utils/common";
export default defineComponent({
  name: "index",
  components: { HealthKanban, HealthDetail, PoolManagement },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const id = store.state.app.user.idNumber;
    const tab = reactive({
      value: "HealthKanban",
      config: [
        {
          name: "HealthKanban",
          title: "健康度看板",
        },
        {
          name: "HealthDetail",
          title: "健康度明细",
        },
        {
          name: "PoolManagement",
          title: "储备管理",
        },
      ],
    });
    const org = reactive({
      value: "",
      data: [],
    });
    const orgStr = computed(() =>
      org.value === "all" ? org.data.join() : org.value
    );
    const filter = ref({});
    const currentQuarter = reactive({
      year: null,
      quarter: ""
    })
    const switchTab = (params = {}, newTab) => {
      filter.value = params;
      tab.value = newTab;
    };

    const updateTag = () => {
      if(tab.value !== 'HealthKanban' && orgStr.value !== "") {
        getHierarchy()
      }
    }

    const initQuarter = async() => {
      $api.getCurrentQuarter().then((res) => {
        Object.assign(currentQuarter, res.data)
      })
    };

    provide("currentQuarter", computed(() =>currentQuarter))
    // 在setup时就获取年度季度 避免晚于子组件加载
    initQuarter()

    onMounted(async () => {
      org.value = sessionStorage.getItem(`${id}_org`) || "";
      org.data = JSON.parse(sessionStorage.getItem(`${id}_orgs`)) || [];
      if (!org.data.length) {
        org.data = await $api.getOrgSelect().then((res) => res.data);
        sessionStorage.setItem(`${id}_orgs`, JSON.stringify(org.data));
      }
      if(orgStr.value !== "") {
        getHierarchy()
      }
      getHistoryHeight()
    });
    watch(
      () => org.value,
      (val) => {
        sessionStorage.setItem(`${id}_org`, val);
      }
    );
    watch(
      () => orgStr.value,
      (val) => {
        $api.getOrgsSelect(val).then((res) => {
          orgsSelect.value = res.data;
        });
      }
    );
    const healthSelect = ref({});
    const attritionRiskSelect = ref({});
    const hierarchySelect = ref([]);
    const motivationSelect = ref([]);
    const orgsSelect = ref([]);
    const onShoreSelect = ref([]);
    // 职级
    const staffGradeOps = ref([]);
    // 评价状态
    const healthEvaluationOps = ref([]);
    // 人员状态
    const healthStaffOps = ref([])

    provide("healthSelect", readonly(healthSelect));
    provide("attritionRiskSelect", readonly(attritionRiskSelect));
    provide("hierarchySelect", readonly(hierarchySelect));
    provide("motivationSelect", readonly(motivationSelect));
    provide("orgsSelect", readonly(orgsSelect));
    provide("onShoreSelect", readonly(onShoreSelect));
    provide("staffGradeOps", readonly(staffGradeOps));
    provide("healthEvaluationOps", readonly(healthEvaluationOps));
    provide("healthStaffOps", readonly(healthStaffOps));

    $api.getHealthSelect().then((res) => {
      healthSelect.value = getObjDictionary(res.data);
    });

    $api.getAttritionRiskSelect().then((res) => {
      attritionRiskSelect.value = getObjDictionary(res.data);
    });
    $api.getMotivationSelect().then((res) => {
      motivationSelect.value = dictionaryOpt(res.data.motivation);
    });
    $api.getOnShoreSelect().then((res) => {
      onShoreSelect.value = dictionaryOpt(res.data.onShore);
    });

    const getObjDictionary = (obj) => {
      const optsList = [];
      for(const i in obj) {
        optsList.push({label:obj[i],value:i})
      }
      return optsList
    };

    // 获取组织层级下拉列表
    const getHierarchy = () => {
      const params = {
        orgs: orgStr.value
      }
      const tagFlag = tab.value === 'PoolManagement' ? 'getHierarchyFromPool' : 'getHierarchySelect'
      $api[tagFlag](params).then((res) => {
        hierarchySelect.value = dictionaryOptionsCode(res.data);
      });
    };

    const resetForm = () => {
      org.value = ''
      getHierarchy()
    }

    // 获取筛选框字典职级数据
    $api
      .getDictionaryList({
        params: {
          dictType: "grade,healthEvaluationStatus,healthStaffStatus",
        },
      })
      .then((res) => {
        staffGradeOps.value = dictionaryOptionsCode(res.data.grade);
        healthEvaluationOps.value = dictionaryOptionsCode(res.data.healthEvaluationStatus);
        healthStaffOps.value = dictionaryOptionsCode(res.data.healthStaffStatus);
      });

    const isCheckSucess = ref(false);
    const checkMsg = ref("");
    $api.checkLeadershipMgmt().then((res) => {
      isCheckSucess.value = res.data;
      checkMsg.value = res.msg;
    });

    //统一获取历史页长度
    const historyHeight = ref(0)
    const getHistoryHeight = () => {
      const clientHeight = document.body.clientHeight
      const tabOffsetTop = document
          .querySelector('.ant-tabs-tabpane')
          .getBoundingClientRect().top;
      const pageHeight = clientHeight - tabOffsetTop - clientHeight * 0.08
      historyHeight.value = pageHeight + 'px'
      console.log(clientHeight,tabOffsetTop)
      // 设置储备管理得页面长度
      if(document.getElementById('pool-content')) document.getElementById('pool-content').style.height = historyHeight.value;
    };
    provide('historyHeight',computed(() => historyHeight))

    return {
      tab,
      org,
      orgStr,
      filter,
      switchTab,
      isCheckSucess,
      checkMsg,
      currentQuarter,
      historyHeight,
      getHistoryHeight,
      healthEvaluationOps,
      healthStaffOps,
      getHierarchy,
      getObjDictionary,
      updateTag,
      resetForm,
    };
  },
});
</script>

<style scoped lang="less">
@import "~@/style/newTitleTab.less";
.app-container {
  :deep(.ant-tabs) {
    .ant-tabs-tabpane {
      background-color: #fff;
      padding: 10px 20px;
      //min-height: 75vh;
    }
  }
  .empty {
    height: calc(100vh - 245px);
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    color: #3182ce;
    img {
      width: 508px;
      height: 303px;
      margin: 102.5px 0 31px 0;
    }
  }
}
</style>
