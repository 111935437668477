<template>
  <!--  健康度明细 -->
  <div :bind="$attrs" class="health-detail" v-if="!showHistoryLayer">
    <div class="charts">
      <Card class="left" title="领导力健康度雷达">
        <LeadershipWeightRadarChart :data="data.radar" :setVal="true" />
      </Card>
      <Card class="center" title="领导力健康度状态（已评价）"
        ><LeadershipLevelBarChart :data="data.chartData" />
      </Card>
      <div class="right">
        <Card class="chart" title="性别分布">
          <LeadershipSexPieChart :data="data.chartData" />
        </Card>
        <Card class="chart" title="入职年限统计">
          <LeadershipYearsBarChart :data="data.chartData" />
        </Card>
      </div>
    </div>
    <CardTitle title="LM领导力健康度明细" size="large" />
    <Filter
      ref="filterRef"
      v-model:params="state.params"
      @search="handleSearch"
      @reset="handleReset"
      :isPoolTab="true"
      :secondMenu="'health'"
    >
      <template #operationBtns>
        <div class="buttons-wrap right">
          <Tooltip title="导出">
            <a-button class="" type="primary" ghost @click="handleExportData">
              <ExportOutlined />
            </a-button>
          </Tooltip>
          <Tooltip title="下载模板：模板中含有多选项，下载后需要启用宏才可正常使用。">
            <a-button v-show="!isHistory" class="ml12" type="primary" ghost @click="handleExportTemplate" v-auth="['system:leadershipManagement:download']">
              <DownloadOutlined />
            </a-button>
          </Tooltip>
          <Tooltip title="导入">
            <a-button v-show="!isHistory" class="ml12" type="primary" ghost @click="importModalVisible = true" v-auth="['system:leadership:import']">
              <ImportOutlined />
            </a-button>
          </Tooltip>
        </div>
      </template>
    </Filter>
    <import-modal
      v-model:visible="importModalVisible"
      :upload-params="{}"
      apiFuncName="importLeadershipDetailData"
      @closeModal="importModalVisible = false"
      @resetTable="handleSearch"
    />
    <HealthDetailTable
      :params="state.params"
      :data="data.page"
      :isPoolTab="true"
      @update="handleSearch"
      @getHistoryPage="getHistoryPage"
    />
  </div>
  <!--  历史记录 -->
  <div class="health-detail" v-if="showHistoryLayer" :style="{height: historyHeight}">
    <HistoryLayer
        @return="handleReturnMain"
        :data="rowData"
        :isPoolTab="true"
    />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, watch, inject } from "vue";
import LeadershipWeightRadarChart from "./components/LeadershipWeightRadarChart.vue";
import LeadershipLevelBarChart from "./components/LeadershipLevelBarChart.vue";
import LeadershipSexPieChart from "./components/LeadershipSexPieChart.vue";
import LeadershipYearsBarChart from "./components/LeadershipYearsBarChart.vue";
import Filter from "./components/Filter.vue";
import HealthDetailTable from "./components/HealthDetailTable.vue";
import Card from "@/components/Card.vue";
import CardTitle from "@/components/CardTitle.vue";
import ImportModal from "@/components/ImportModal";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { cloneDeep } from "lodash";
import { downloadFile } from "@/utils/common";
import { message } from "ant-design-vue";
import HistoryLayer from "./components/HistoryLayer.vue";
import { ExportOutlined, DownloadOutlined, ImportOutlined } from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'

export default defineComponent({
  name: "HealthDetail",
  components: {
    Card,
    CardTitle,
    LeadershipWeightRadarChart,
    LeadershipLevelBarChart,
    LeadershipSexPieChart,
    LeadershipYearsBarChart,
    Filter,
    HealthDetailTable,
    ImportModal,
    HistoryLayer,
    ExportOutlined,
    DownloadOutlined,
    ImportOutlined,
    Tooltip
  },
  props: {
    orgs: String,
    filter: Object,
    currentQuarter: Object,
    switchTab: Function,
  },
  emits: ['getHistoryHeight'],
  setup(props) {
    const { $api } = useGlobalPropertyHook();
    const filterRef = ref(null);
    const data = reactive({
      radar: [],
      chartData: [],
      page: []
    });
    const baseParams = {
      isPool: "0",
      costCtrList: props.orgs.split(","),
      pageIndex: 1,
      pageSize: 10,
      fullName: "",
      health: [],
      attritionRisk: [],
      hierarchy: undefined,
      orgThreeTwo: [],
      orgFourTwo: [],
      onShore: [],
      isNew: undefined,
      isKeyTalent: undefined,
      year: props.currentQuarter.year,
      quarter: props.currentQuarter.quarter,
      staffStatus: '0',
      evaluationStatus: '',
      grade: [],
      motivationList: [],
      jobStatus: 1,
    };
    const state = reactive({
      params: cloneDeep(baseParams),
    });
    const isHistory = ref(false)
    const handleSearch = ({ pageIndex = 1, pageSize } = {}) => {
      if (pageIndex) state.params.pageIndex = pageIndex;
      if (pageSize) state.params.pageSize = pageSize;
      isHistory.value = isHistoricQuarter()
      // 雷达接口
      $api.getDetailRadarData(state.params).then((res) => {
        data.radar = res.data
      })
      // chart接口
      $api.getDetailChartData(state.params).then((res) => {
        data.chartData = res.data[0]
      })
      // 表格数据
      $api.getLeadershipDetailData(state.params).then((res) => {
        if (res.msg) {
          message.warning(res.msg);
        }
        data.page = res.data;
      });
    };
    const handleReset = () => {
      state.params = cloneDeep(baseParams);
      handleSearch();
    };
    const handleExportTemplate = () => {
      $api.exportLeadershipDetailTemplate(props.orgs).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    // 导出事件，需要后端加字段处理
    const handleExportData = () => {
      $api.exportLeadershipDetailData(state.params).then((res) => {
        downloadFile(res.file, "健康度明细"+state.params.year+"年"+state.params.quarter+".xls");
      });
    };

    const isHistoricQuarter = () => {
      return !(state.params.quarter === props.currentQuarter.quarter && state.params.year === props.currentQuarter.year)
    }

    const rowData = ref({})
    const showHistoryLayer = ref(false)
    const getHistoryPage = (data, isShow) => {
      rowData.value = data
      showHistoryLayer.value = isShow
    }
    // 获取历史页长度
    const historyHeight = ref(inject('historyHeight').value.value)
    const handleReturnMain = () => {
      showHistoryLayer.value = false
      handleSearch()
    }
    watch(
      () => props.filter,
      (filter) => {
        state.params = Object.assign({}, baseParams, filter, props.currentQuarter);
        // nextTick(() => {
        //   filterRef.value.setShow(Object.keys(filter).length > 0);
        // });
        handleSearch();
      },
      { immediate: true, deep: true }
    );
    watch(
      () => props.orgs,
      () => {
        baseParams.costCtrList = props.orgs.split(",");
        filterRef.value?.handleReset();
      }
    );
    return {
      filterRef,
      importModalVisible: ref(false),
      state,
      data,
      handleSearch,
      handleReset,
      handleExportTemplate,
      handleExportData,
      isHistoricQuarter,
      isHistory,
      getHistoryPage,
      rowData,
      showHistoryLayer,
      historyHeight,
      handleReturnMain
    };
  },
});
</script>

<style scoped lang="less">
.health-detail {
  height: 100%;
  .charts {
    height: 396px;
    display: flex;
    margin-bottom: 24px;
    .left {
      min-width: 400px;
      width: calc(100% - 500px - 400px - 48px);
      height: 100%;
    }
    .center {
      min-width: 400px;
      width: 500px;
      height: 100%;
      margin: 0 24px;
    }
    .right {
      min-width: 400px;
      width: 400px;
      height: 100%;
      .chart {
        height: 194px;
        margin-bottom: 8px;
        padding: 10px 16px;
      }
    }
  }
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
