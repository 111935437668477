<template>
  <div ref="addUserModalRef">
    <a-modal
        :getContainer="()=>$refs.addUserModalRef"
        title="新增"
        okText="保存"
        cancelText="取消"
        width="20.8vw"
        :maskClosable="false"
        :visible="visible"
        @update:visible="(val) => $emit('update:visible', val)"
        @cancel="$emit('update:visible', false)"
        @ok="handleSave"
        centered
    >
      <div class="add-user">
        <a-select
            showSearch
            label-in-value
            placeholder="姓名/邮箱/员工号"
            size="large"
            style="width: 100%;"
            :filter-option="false"
            option-label-prop="label"
            :not-found-content="fetching ? undefined : null"
            @search="handleSearchUser"
            @select="handleSelect"
        >
          <template v-if="fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
          <template #suffixIcon><SearchOutlined /></template>
          <a-select-option
              v-for="item in data"
              :key="item.email"
              :value="item.fullName"
              v-bind="{ item }"
          >
            <div>
              <div class="opt" style="font-size: 16px; font-weight: 500">
                <span>{{ item.fullName }}</span>
                <span class="opt_id">{{ item.idNumber }}</span>
              </div>
              <div>{{ item.email }}</div>
            </div>
          </a-select-option>
        </a-select>
        <div class="content">
          <div class="row">
            <div class="label">姓名：</div>
            <div class="value blue">{{ user.fullName ? user.fullName : '-' }}</div>
          </div>
          <div class="row">
            <div class="label">邮箱：</div>
            <div class="value">{{ user.email ? user.email : '-' }}</div>
          </div>
          <div class="row">
            <div class="label">员工ID：</div>
            <div class="value">{{ user.idNumber ? user.idNumber : '-' }}</div>
          </div>
          <div class="row">
            <div class="label">性别：</div>
            <div class="value">
              <span>{{ user.genderKey === 'Male'? '男': user.genderKey === 'Female' ? '女' : '-' }}</span>
            </div>
          </div>
          <div class="row">
            <div class="label">级别：</div>
            <div class="value">{{ user.psGroup ? user.psGroup : '-' }}</div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from "vue";
import { debounce } from "lodash";
import { SearchOutlined } from "@ant-design/icons-vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from "ant-design-vue";

export default defineComponent({
  components: { SearchOutlined },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    orgs: String,
  },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const user = ref({});
    const state = reactive({
      data: [],
      fetching: false,
    });

    const handleSearchUser = debounce((value) => {
      if (!value) {
        state.data = [];
        return;
      }
      state.fetching = true;
      $api
        .searchLeadershipUser({
          search: value,
          orgs: props.orgs,
        })
        .then((res) => {
          state.data = res.data;
        })
        .finally(() => {
          state.fetching = false;
        });
    }, 500);
    const handleSelect = (val, { item }) => {
      user.value = item;
    };
    const handleSave = () => {
      $api
        .saveLeadershipDetailData({
          userId: user.value.id,
          idNumber: user.value.idNumber,
          fullName: user.value.fullName,
          genderKey: user.value.genderKey,
          psGroup: user.value.psGroup,
        })
        .then(() => {
          message.success("新增成功");
          emit("update:visible", false);
          emit("update");
        });
    };
    return {
      user,
      ...toRefs(state),
      handleSearchUser,
      handleSelect,
      handleSave,
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@/style/aModal.less";
.add-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  :deep(.ant-select-show-search) {
    .ant-select-arrow {
      top: 40%;
      right: 16px;
      height: 12px;
      margin-top: -6px;
      font-size: 18px;
    }
  }
  .content {
    width: 100%;
    margin-top: 12px;
    padding: 14px 16px;
    font-size: 16px;
    line-height: 22px;
    box-shadow: 0 0 10px #eee ;
    .row {
      display: flex;
      margin: 2px 0;
      .label {
        text-align: right;
        width: 70px;
      }
      .value {
        margin-left: 8px;
        width: calc(100% - 70px);
        min-height: 100%;
        &.blue{
          color: #12abdb;
        }
      }
    }
  }
}
.opt{
  padding: 1px;
  .opt_id{
    margin-left: 12px;
    padding: 0 4px;
    line-height: 20px;
    border: 1px solid #b3b3b3;
  }
}
</style>
