<template>
  <div class="filter">
    <FilterLayout @doReset="handleReset"
                  @doSearch="handleSearch"
                  ref="filterLayoutRef" class="mb12"
                  @showMoreForm="showMoreForm"
                  :moreBtnVisible="moreBtnVisible"
                  :isShowMoreFlag="isShowMoreList">
      <template #customform>
        <a-form
            ref="formRef"
            class="form rms-form"
            layout="vertical"
            :model="filter"
        >
          <template v-for="(item,index) in queryList" :key="index">
            <template v-if="index<=formLength">
              <a-form-item :label="item.code" name="name" :class="item.type === '7'? 'rms-form-item treeExpand' : 'rms-form-item'">
                <template v-if="item.type==='2'">
                  <a-select
                      v-model:value="filter[item.inputDataType]"
                      :options="getOptions(item.inputDataType)"
                      placeholder="请选择"
                      allowClear
                      show-search
                      :maxTagCount="1"
                  >
                  </a-select>
                </template>
                <template v-if="item.type==='3'">
                  <a-input
                      v-model:value="filter[item.inputDataType]"
                      :placeholder="getPlaceHoder(item.inputDataType)"
                      allowClear
                      autocomplete="off"
                  />
                </template>
                <template v-if="item.type==='5'">
                  <SelectWithAll
                      v-model:value="filter[item.inputDataType]"
                      placeholder="请选择"
                      mode="multiple"
                      showArrow
                      :maxTagCount="1"
                      allowClear
                      :options="getOptions(item.inputDataType)"
                      @change="getSelectChange(item.inputDataType)"
                      :tag-count="(item.inputDataType==='health'||item.inputDataType==='attritionRisk'||item.inputDataType==='grade')?3:1"
                  >
                  </SelectWithAll>
                </template>
                <template v-if="item.type==='6'">
                  <a-date-picker
                      v-model:value="filter.year"
                      placeholder="请选择"
                      mode="year"
                      @panelChange="panelChange"
                      format="YYYY"
                      :open="isYearOpen"
                      @openChange="openChange"
                      :inputReadOnly="true"
                      :allowClear="false"
                  />
                </template>
                <template v-if="item.type==='7'">
                  <a-tree-select
                      :dropdownStyle="{
              maxHeight: '300px',
            }"
                      allowClear
                      multiple
                      tree-checkable
                      :maxTagCount="1"
                      :replaceFields="{ title: 'child', value: 'id' }"
                      :tree-data="orgsSelect"
                      placeholder="请选择"
                      v-model:value="orgs"
                      v-model:treeExpandedKeys="treeExpandedKeys"
                      @treeExpand="handleTreeExpand"
                      @change="handleOrgChange"
                  />
                </template>
              </a-form-item>
            </template>
          </template>
          <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index>formLength">
                <a-form-item :label="item.code" name="name" :class="item.type === '7'? 'rms-form-item treeExpand' : 'rms-form-item'">
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="filter[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        allowClear
                        show-search
                        :maxTagCount="1"
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="filter[item.inputDataType]"
                        :placeholder="getPlaceHoder(item.inputDataType)"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="filter[item.inputDataType]"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        :maxTagCount="1"
                        allowClear
                        :options="getOptions(item.inputDataType)"
                        @change="getSelectChange(item.inputDataType)"
                        :tag-count="(item.inputDataType==='health'||item.inputDataType==='attritionRisk'||item.inputDataType==='grade')?3:1"
                    >
                    </SelectWithAll>
                  </template>
                  <template v-if="item.type==='6'">
                    <a-date-picker
                        v-model:value="filter.year"
                        placeholder="请选择"
                        mode="year"
                        @panelChange="panelChange"
                        format="YYYY"
                        :open="isYearOpen"
                        @openChange="openChange"
                        :inputReadOnly="true"
                        :allowClear="false"
                    />
                  </template>
                  <template v-if="item.type==='7'">
                    <a-tree-select
                        :dropdownStyle="{
                          maxHeight: '300px',
                        }"
                        allowClear
                        multiple
                        tree-checkable
                        :maxTagCount="1"
                        :replaceFields="{ title: 'child', value: 'id' }"
                        :tree-data="orgsSelect"
                        placeholder="请选择"
                        v-model:value="orgs"
                        v-model:treeExpandedKeys="treeExpandedKeys"
                        @treeExpand="handleTreeExpand"
                        @change="handleOrgChange"
                    />
                  </template>
                </a-form-item>
              </template>
            </template>
          </MoreFormList>
        </a-form>
      </template>
      <template #operationBtns>
        <slot name="operationBtns"></slot>
      </template>
    </FilterLayout>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="sortFlag" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </div>
</template>

<script>
import {defineComponent, reactive, ref, inject, watch, nextTick, onMounted} from "vue";
import { enumList } from "@/api/enumStatic";
import moment from "moment";
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import {debounce} from 'lodash'
import {useGlobalPropertyHook} from '@/hooks/common'

export default defineComponent({
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
    isPoolTab: {
      type: Boolean,
      default: false,
    },
    secondMenu: {
      type: String,
      default: '',
    },
  },
  components: { MoreFormList, FormModalBox },
  emits: ["search", "reset", "update:params"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const show = ref(true);
    const orgs = ref([]);
    let formLength = props.secondMenu === 'health'? 3:4
    const sortFlag = ref(false)
    sortFlag.value = props.secondMenu === 'health' ? false: true
    let initData = {
      condition: "",
      health: [],
      attritionRisk: [],
      hierarchy: undefined,
      motivationList: [],
      onShore: [],
      isNew: undefined,
      isKeyTalent: undefined,
      staffStatus: '0',
      year: null,
      quarter: "",
      evaluationStatus: undefined,
      grade: [],
      jobStatus: 1,
    }
    const filter = reactive({...initData});
    let orgData = {
      orgThreeTwo: [],
      orgFourTwo: [],
    };
    const handleOrgChange = (value, label, { allCheckedNodes = [] }) => {
      orgData = {
        orgThreeTwo: [],
        orgFourTwo: [],
      };
      const updateOrgData = (nodes = []) => {
        nodes.forEach((item) => {
          let child =
            item?.node?.props?.dataRef?.child || item?.props?.dataRef?.child;
          let hierarchy =
            item?.node?.props?.dataRef?.hierarchy ||
            item?.props?.dataRef?.hierarchy;
          if (child && hierarchy) {
            orgData[
              hierarchy.replace(/_(\w)/g, (_, letter) => letter.toUpperCase())
            ]?.push(child);
          }
          if (item?.children?.length) updateOrgData(item?.children);
        });
      };
      updateOrgData(allCheckedNodes);
      // emit("update:params", Object.assign({}, props.params, orgData));
      // handleSearch();
    };
    const handleSearch = () => {
      emit("update:params", Object.assign({}, props.params, filter, orgData));
      nextTick(() => emit("search"));
    };
    const handleReset = () => {
      orgs.value = [];
      orgData = {
        orgThreeTwo: [],
        orgFourTwo: [],
      };
      Object.assign(filter,initData)
      emit("reset");
    };
    const moreSeachHanlder = () => {
      handleSearch()
      closeMoreForm()
    }
    const isYearOpen = ref(false);
    const openChange = (panelStatus) => {
      if (panelStatus) {
        isYearOpen.value = true;
      } else {
        isYearOpen.value = false;
      }
    };
    const panelChange = (year) => {
      filter.year = moment(year).format("YYYY");
      isYearOpen.value = false;
    };

    watch(
      () => props.params,
      (params) => {
        for (let key in filter) {
          filter[key] = params[key];
        }
      },
      { immediate: true, deep: true }
    );
    const orgsSelect = inject("orgsSelect");
    const staffGradeOptions = inject("staffGradeOps");
    const healthEvaluationOps = inject("healthEvaluationOps");
    const healthStaffOps = inject("healthStaffOps");

    const healthSelect = inject("healthSelect")
    const attritionRiskSelect = inject("attritionRiskSelect")
    const hierarchySelect = inject("hierarchySelect")
    const motivationSelect = inject("motivationSelect")
    const onShoreSelect = inject("onShoreSelect")

    const treeExpandedKeys = ref([]);
    const handleTreeExpand = (expandedKeys) => {
      treeExpandedKeys.value = expandedKeys;
    };
    watch(
      orgsSelect,
      (val) => {
        const getAllIds = (arr = [], Ids = []) => {
          return arr.reduce((ids, { id, children = [] }) => {
            ids = ids.concat([id]);
            if (children.length) ids = ids.concat(getAllIds(children, ids));
            return ids;
          }, Ids);
        };
        treeExpandedKeys.value = getAllIds(val);
      },
      {
        immediate: true,
      }
    );
    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'leadership',
        secondMenu: props.secondMenu,
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > formLength+1?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getPlaceHoder = (data)=> {
      switch (data) {
        case 'condition':
          return '姓名/员工ID'
        default :
          ''  // 如果没
      }
    }
    const getOptions = (data)=> {
      switch (data) {
        case 'evaluationStatus':
          return healthEvaluationOps.value
        case 'health':
          return healthSelect.value
        case 'attritionRisk':
          return attritionRiskSelect.value
        case 'hierarchy':
          return hierarchySelect.value
        case 'grade':
          return staffGradeOptions.value
        case 'orgFourTwo':
          return orgData.orgFourTwo
        case 'onShore':
          return onShoreSelect.value
        case 'isNew':
          return enumList.tagOps
        case 'isKeyTalent':
          return enumList.tagOps
        case 'motivationList':
          return motivationSelect.value
        case 'staffStatus':
          return healthStaffOps.value
        case 'quarter':
          return enumList.quarterOps
        case 'jobStatus':
          return [{value: 1, label: '在职'}, {value: 2, label: '离职'}]
        default :
          ''  // 如果没
      }
    }
    const getSelectChange = debounce((data)=> {
      console.log(data)
      switch (data) {
        case 'orgThreeTwoList':
          return 11
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    return {
      show,
      healthSelect,
      attritionRiskSelect,
      hierarchySelect,
      motivationSelect,
      orgsSelect,
      staffGradeOptions,
      onShoreSelect,
      orgs,
      treeExpandedKeys,
      handleTreeExpand,
      filter,
      handleOrgChange,
      handleSearch,
      handleReset,
      enumList,
      panelChange,
      openChange,
      isYearOpen,
      healthEvaluationOps,
      healthStaffOps,
      queryFormList,
      queryList,
      checkList,
      getPlaceHoder,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      formLength,
      sortFlag,
      moreSeachHanlder
    };
  },
});
</script>

<style lang="less" scoped>
:deep(.ant-calendar-picker) {
  width: 100%;
}
.treeExpand {
  :deep(.ant-select-multiple .ant-select-selection-item) {
    max-width: 70% !important;
  }
}
</style>
