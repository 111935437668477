<template>
  <div class="member-card">
    <a-avatar
      class="avatar"
      v-if="avatar.startsWith('#')"
      :style="{
        background: avatar,
      }"
      :size="64"
      >{{ nameInitials }}</a-avatar
    >
    <a-avatar class="avatar" v-else :size="64" :src="avatar" />
    <div class="info">
      <div class="name ellipsis" :title="name">{{ name }}</div>
      <div class="email ellipsis" :title="email">{{ email }}</div>
      <div>组织层级：{{ hierarchy ? hierarchy : "-" }}</div>
      <div v-if="isShowOrg">组织：{{ org }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MemberCard",
  props: {
    avatar: String,
    email: String,
    name: String,
    nameInitials: String,
    isShowOrg : Boolean,
    hierarchy: String,
    org: String,
  },
};
</script>

<style lang="less" scoped>
.member-card {
  height: 131px;
  width: 354px;
  padding: 16px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: #666666;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  .avatar {
    flex-shrink: 0;
  }
  .info {
    max-width: calc(100% - 84px);
    margin-left: 20px;
    .ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .name {
      font-weight: 500;
      font-size: 18px;
      color: #2d3748;
    }
    .email {
      color: #505050;
    }
  }
}
</style>
