<template>
  <div :bind="$attrs">
    <a-table
      class="ant-table-striped"
      rowKey="userId"
      :columns="columns"
      :data-source="data.content"
      :scroll="{
        x: infoCollapse ? 3460 : 2700,
        scrollToFirstRowOnChange: true,}"
      :pagination="pagination"
    >
      <template #infoCollapse>
        <div class="collapse-cell">
          <div class="collapse-btn" @click="infoCollapse = !infoCollapse">
            <DoubleLeftOutlined v-if="infoCollapse" />
            <DoubleRightOutlined v-else />
          </div>
        </div>
      </template>
      <template #action="{ record }">
        <a-space class="flex-box">
          <a-tooltip class="tooltip-list" placement="top" color="#fff">
            <template #title>
              <span class="font-color">更新</span>
            </template>
            <!--   明细页面并且不是当前季度、当前年、不是LM --- 灰色 -->
            <span
                v-auth="['system:leadership:update']"
                class="operationLink underline icon-btn"
                v-show="isPoolTab"
                :class="{ disabled: (!isCurrentTime(record) || !isOnJobLm(record))}"
                @click="handleUpdateClick(record)"
            ><FileSyncOutlined :style="{display:'block'}" /></span>
            <!-- 储备管理，离职人员 --- 灰色       -->
            <span
                v-auth="['system:leadership:reserveUpdate']"
                v-show="!isPoolTab"
                class="operationLink underline icon-btn"
                :class="{ disabled: !isOnJobPerson(record)}"
                @click="handleUpdateClick(record)"
            ><FileSyncOutlined :style="{display:'block'}" /></span>
          </a-tooltip>
          <a-tooltip class="tooltip-list" placement="top" color="#fff">
            <template #title>
              <span class="font-color">个人信息</span>
            </template>
            <span class="operationLink underline icon-btn" @click="handleInfoClick(record)" v-auth="['system:leadershipManagement:info']">
            <IdcardOutlined :style="{display:'block'}" />
          </span>
          </a-tooltip>
          <a-tooltip class="tooltip-list" placement="top" color="#fff" v-if="!isPoolTab">
            <template #title>
              <span class="font-color">删除</span>
            </template>
            <!-- 储备管理页面并且该人员为离职人员---灰色 -->
            <span
                v-auth="['system:leadership:delete']"
                class="redLink underline icon-btn"
                :class="{ disabled: !isPoolTab && !isOnJobPerson(record) }"
                v-show="!isPoolTab"
                @click="handleDeleteClick(record)"
            ><DeleteOutlined :style="{display:'block'}" /></span>
          </a-tooltip>
          <a-tooltip class="tooltip-list" placement="top" color="#fff" v-if="isPoolTab">
            <template #title>
              <span class="font-color">停用</span>
            </template>
            <!-- 健康度明细tab 删除 只hrbp看到-->
            <span
                v-auth="['system:leadership:deleteLmAction']"
                class="redLink underline icon-btn"
                :class="{ disabled: isPoolTab && (!isCurrentTime(record) || !isOnJobLm(record)) }"
                v-show="isPoolTab"
                @click="handleLmDeleteClick(record)"
            ><MinusCircleOutlined :style="{display:'block'}" /></span>
          </a-tooltip>
        </a-space>
      </template>
    </a-table>
    <!--    <HistoryLayer
      v-if="showHistoryLayer"
      @return="showHistoryLayer = false"
      :data="rowData"
      :isPoolTab="isPoolTab"
    />-->
    <UpdateDrawer
      v-if="showUpdateDrawer"
      :data="rowData"
      v-model:visible="showUpdateDrawer"
      :isPoolTab="isPoolTab"
      @userInfo="handleInfoClick(rowData)"
      @save="$emit('update')"
    />
    <EmpInfo
      v-if="showInfoDrawer"
      :isShowingEmpInfo="showInfoDrawer"
      :userDataList="userDataList"
      :getId="rowData.userId"
      @close-emp-info="showInfoDrawer = false"
      :showworkExperienceCol="true"
    />
  </div>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" :footer="footer" />
  <ModalBox v-model="modelValue1" :ok="handleOk1" :content="content1" :showIconCom="showIconCom1" />
</template>

<script>
import { defineComponent, ref, reactive, watch, inject } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import UpdateDrawer from "./UpdateDrawer.vue";
import EmpInfo from "@/components/EmpInfo.vue";
import { message } from "ant-design-vue";
import {
  totalLeadershipRender,
  leadershipRender,
  tooltipRender,
} from "../LeadershipTableRender";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  IdcardOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  FileSyncOutlined,
} from "@ant-design/icons-vue";
// import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import moment from "moment";
import ModalBox from '@/components/ModalBox'
export default defineComponent({
  components: {
    UpdateDrawer,
    EmpInfo,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    ModalBox,
    IdcardOutlined,
    DeleteOutlined,
    MinusCircleOutlined,
    FileSyncOutlined,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        content: [],
        pageable: { page: 0, size: 10 },
        total: 0,
      }),
    },
    deleteAction: {
      type: Boolean,
      default: false,
    },
    isPoolTab: {
      type: Boolean,
      default: false,
    },
  },
  emits:["getHistoryPage"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    // const store = useStore();
    const showHistoryLayer = ref(false);
    const rowData = ref({});
    const userDataList = ref({});
    const currentQuarter = inject("currentQuarter")
    const handleNameClick = (record) => {
      rowData.value = record;
      showHistoryLayer.value = true;
      emit("getHistoryPage",rowData.value,true)
    };
    const showUpdateDrawer = ref(false);
    const handleUpdateClick = (record) => {
      rowData.value = record;
      showUpdateDrawer.value = true;
    };
    const showInfoDrawer = ref(false);
    const handleInfoClick = (record) => {
      rowData.value = record;
      $api.getOneUser(record.userId).then((res) => {
        userDataList.value = res.data;
        showInfoDrawer.value = true;
      });
    };
    const modelValue1 = ref(false)
    const content1 = ref('')
    const showIconCom1 = ref('')
    const modalData1 = ref()
    const handleOk1 = () => {
      modalData1.value?deleteLeadershipDetailData(modalData1.value):'';
    }
    const deleteLeadershipDetailData = (id) => {
      $api.deleteLeadershipDetailData(id).then(() => {
        message.success("删除成功");
        emit("update");
      });
    }
    const handleDeleteClick = (record) => {
      content1.value = '确定删除这条数据吗?'
      showIconCom1.value = 'ExclamationCircleOutlined'
      modalData1.value = record.id
      modelValue1.value = true
      /*Modal.confirm({
        title: () => '提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: "确定删除这条数据吗?",
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          $api.deleteLeadershipDetailData(record.id).then(() => {
            message.success("删除成功");
            emit("update");
          });
        },
      });*/
    };

    const operationTimeRender = ({ text }) => {
      return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
    };
    const infoCollapse = ref(false);
    const columns = computed(() => {
      let infoCols = [
        {
          dataIndex: "infoCollapse",
          key: "infoCollapse",
          width: 0,
          slots: { title: "infoCollapse" },
          fixed: "left",
        },
      ];
      if (infoCollapse.value) {
        infoCols = [
          {
            dataIndex: "infoCollapse",
            key: "infoCollapse",
            width: 0,
            slots: { title: "infoCollapse" },
            fixed: "left",
          },
          {
            title: "员工ID",
            width: 120,
            dataIndex: "idNumber",
            key: "idNumber",
            ellipsis: true,
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
          },
          {
            title: "职级",
            width: 100,
            dataIndex: "grade",
            key: "grade",
            ellipsis: true,
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
          },
          // {
          //   title: "团队名称",
          //   width: 100,
          //   dataIndex: "orgThreeTwo",
          //   key: "orgThreeTwo",
          //   align: "center",
          //   customHeaderCell: collapseCellFunc,
          //   customCell: collapseTitleCellFunc,
          // },
          {
            title: "Team",
            width: 130,
            dataIndex: "orgThreeTwo",
            key: "orgThreeTwo",
            ellipsis: true,
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
          },
          {
            title: "Sub Team",
            width: 130,
            dataIndex: "orgFourTwo",
            key: "orgFourTwo",
            ellipsis: true,
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
          },
          {
            title: "Onshore/RDC",
            width: 200,
            dataIndex: "orgFiveOne",
            key: "orgFiveOne",
            ellipsis: true,
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
          },
          {
            title: "组织层级",
            width: 100,
            dataIndex: "hierarchy",
            key: "hierarchy",
            ellipsis: true,
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
          },
        ];
      }
      // 按页签进行动态新增的三列表格字段
      const newColums = [
        {
          title: "年/季度",
          width: 120,
          dataIndex: "yearAndQuarter",
          key: "yearAndQuarter",
          ellipsis: true,
        },
        {
          title: "新LM",
          width: 120,
          dataIndex: "isNew",
          key: "isNew",
          ellipsis: true,
          customRender: ({ text }) =>
            Number(text) === 1 ? "是" : text === null ? "" : "否",
        },
        {
          title: "关键人才",
          width: 120,
          dataIndex: "isKeyTalent",
          key: "isKeyTalent",
          ellipsis: true,
          customRender: ({ text }) => {
            return Number(text) === 1 ? "是" : text === null ? "" : "否";
          },
        },
        {
          title: "直接下属人数",
          width: 150,
          dataIndex: "underNumber",
          key: "underNumber",
          ellipsis: true,
          customRender: tooltipRender,
        },
      ];
      // 如果是健康度明细tab页签,则加入新增的三列,储备管理页签不加这三列
      if (props.isPoolTab) {
        return [
          {
            title: "姓名",
            width: 140,
            dataIndex: "fullName",
            key: "fullName",
            fixed: "left",
            customRender: ({ text, record }) => (
              <span
                class="link underline"
                onClick={() => handleNameClick(record)}
              >
                {text}
              </span>
            ),
          },
          ...infoCols,

          ...newColums,

          {
            title: "识别动机",
            width: 100,
            dataIndex: "motivation",
            key: "motivation",
            ellipsis: true,
            customRender: tooltipRender,
          },
          {
            title: "动机备注",
            width: 120,
            dataIndex: "motivationRemarks",
            key: "motivationRemarks",
            ellipsis: true,
            customRender: tooltipRender,
          },
          {
            title: "领导意愿",
            width: 100,
            dataIndex: "willingness",
            key: "willingness",
            ellipsis: true,
            customRender: leadershipRender,
          },
          {
            title: "以人为本",
            width: 100,
            dataIndex: "humanized",
            key: "humanized",
            ellipsis: true,
            customRender: leadershipRender,
          },
          {
            title: "有效沟通",
            width: 100,
            dataIndex: "communication",
            key: "communication",
            ellipsis: true,
            customRender: leadershipRender,
          },
          {
            title: "协作",
            width: 80,
            dataIndex: "collaboration",
            key: "collaboration",
            ellipsis: true,
            customRender: leadershipRender,
          },
          {
            title: "领导技能",
            width: 100,
            dataIndex: "skill",
            key: "skill",
            ellipsis: true,
            customRender: leadershipRender,
          },
          {
            title: "领导精力",
            width: 100,
            dataIndex: "energy",
            key: "energy",
            ellipsis: true,
            customRender: leadershipRender,
          },
          {
            title: "敬业投入",
            width: 100,
            dataIndex: "inputs",
            key: "inputs",
            ellipsis: true,
            customRender: leadershipRender,
          },
          {
            title: "总计",
            width: 80,
            dataIndex: "total",
            key: "total",
            ellipsis: true,
            customRender: totalLeadershipRender,
          },
          {
            title: "备注",
            width: 200,
            dataIndex: "remarks",
            key: "remarks",
            ellipsis: true,
            customRender: tooltipRender,
          },
          {
            title: "行动",
            width: 100,
            dataIndex: "action",
            key: "action",
            ellipsis: true,
            customRender: tooltipRender,
          },
          {
            title: "DISC",
            width: 100,
            dataIndex: "disc",
            key: "disc",
            ellipsis: true,
            customRender: tooltipRender,
          },
          {
            title: "入职年限",
            width: 100,
            dataIndex: "years",
            key: "years",
            ellipsis: true,
          },
          {
            title: "最近沟通时间",
            dataIndex: "communicationTime",
            key: "communicationTime",
            ellipsis: true,
          },
          // {
          //   title: "记录时间",
          //   width: 100,
          //   dataIndex: "recordingTime",
          //   key: "recordingTime",
          //   align: "center",
          // },
          {
            title: "操作时间",
            dataIndex: "operationTime",
            key: "operationTime",
            customRender: operationTimeRender,
          },

          {
            title: "操作",
            key: "operation",
            fixed: "right",
            width: 140, //infoCollapse.value ? "3%" : '5%',
            slots: { customRender: "action" },
          },
        ];
      } else {
        return [
          {
            title: "姓名",
            width: 140,
            dataIndex: "fullName",
            key: "fullName",
            fixed: "left",
            customRender: ({ text, record }) => (
              <span
                class="link underline"
                onClick={() => handleNameClick(record)}
              >
                {text}
              </span>
            ),
          },
          ...infoCols,

          {
            title: "识别动机",
            width: 100,
            dataIndex: "motivation",
            key: "motivation",
            ellipsis: true,
            customRender: tooltipRender,
          },
          {
            title: "动机备注",
            width: 100,
            dataIndex: "motivationRemarks",
            key: "motivationRemarks",
            ellipsis: true,
            customRender: tooltipRender,
          },
          {
            title: "领导意愿",
            width: 80,
            dataIndex: "willingness",
            key: "willingness",
            customRender: leadershipRender,
          },
          {
            title: "以人为本",
            width: 80,
            dataIndex: "humanized",
            key: "humanized",
            customRender: leadershipRender,
          },
          {
            title: "有效沟通",
            width: 80,
            dataIndex: "communication",
            key: "communication",
            customRender: leadershipRender,
          },
          {
            title: "协作",
            width: 80,
            dataIndex: "collaboration",
            key: "collaboration",
            customRender: leadershipRender,
          },
          {
            title: "领导技能",
            width: 80,
            dataIndex: "skill",
            key: "skill",
            customRender: leadershipRender,
          },
          {
            title: "领导精力",
            width: 80,
            dataIndex: "energy",
            key: "energy",
            customRender: leadershipRender,
          },
          {
            title: "敬业投入",
            width: 80,
            dataIndex: "inputs",
            key: "inputs",
            customRender: leadershipRender,
          },
          {
            title: "总计",
            width: 80,
            dataIndex: "total",
            key: "total",
            customRender: totalLeadershipRender,
          },
          {
            title: "备注",
            width: 150,
            dataIndex: "remarks",
            key: "remarks",
            ellipsis: true,
            customRender: tooltipRender,
          },
          {
            title: "行动",
            width: 80,
            dataIndex: "action",
            key: "action",
            ellipsis: true,
            customRender: tooltipRender,
          },
          {
            title: "DISC",
            width: 80,
            dataIndex: "disc",
            key: "disc",
            ellipsis: true,
            customRender: tooltipRender,
          },
          {
            title: "入职年限",
            width: 80,
            dataIndex: "years",
            key: "years",
          },
          {
            title: "最近沟通时间",
            dataIndex: "communicationTime",
            key: "communicationTime",
            width: 140,
          },
          // {
          //   title: "记录时间",
          //   width: 100,
          //   dataIndex: "recordingTime",
          //   key: "recordingTime",
          //   align: "center",
          // },
          {
            title: "操作时间",
            dataIndex: "operationTime",
            key: "operationTime",
            customRender: operationTimeRender,
            width: 140,
          },

          {
            title: "操作",
            key: "operation",
            fixed: "right",
            width: 140, //infoCollapse.value ? '3%' : '5%',
            slots: { customRender: "action" },
          },
        ];
      }
    });
    const collapseCellFunc = () => {
      return {
        style: {
          "background-color": "#F3F3F3",
        },
      };
    };
    const collapseTitleCellFunc = () => {
      return {
        style: {
          "background-color": "#FBFBFB",
        },
      };
    };
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (pageIndex) => {
        emit("update", { pageIndex });
      },
      onShowSizeChange: (pageIndex, pageSize) => {
        emit("update", { pageIndex: 1, pageSize });
      },
    });

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const footer = ref()
    const modalData = ref()
    const handleOk = () => {
      modalData.value?deleteLmData(modalData.value):'';
    }
    const handleLmDeleteClick = async (record) => {
      // 判断名下是否有人
      const { data, code, msg } = await $api.handleCheckPeople(record.userId)
      // 1有人 2都离职了 3没人
      if(code !== 200) {
        message.error(msg)
        return
      }
      if(data === '1') {
        content.value = '该LM名下仍有在职人员和即将入职人员，不能停用该人员LM角色，请先去My connect上进行操作。'
        showIconCom.value = 'ExclamationCircleOutlined'
        footer.value = true
        modelValue.value = true
        /*Modal.info({
          title: () => '提示',
          icon: createVNode(ExclamationCircleOutlined),
          content: "该LM名下仍有在职人员和即将入职人员，不能停用该人员LM角色，请先去My connect上进行操作。",
          okText: "确定",
          centered: true,
        });*/
      }else {
        // 如果该LM名下没有在职人员或名下所属人员已经全部离职，给出提示，二次确定后，允许其删除该人员的LM角色
        modalData.value = record.userId
        content.value = '是否确定要删除该人员的LM角色？停用后将不能再对该人员进行评价，请确定。'
        showIconCom.value = 'ExclamationCircleOutlined'
        footer.value = false
        modelValue.value = true
        /*Modal.confirm({
          title: () => '提示',
          content: "是否确定要删除该人员的LM角色？停用后将不能再对该人员进行评价，请确定。",
          icon: createVNode(ExclamationCircleOutlined),
          okText: "确定",
          cancelText: "取消",
          centered: true,
          //  删除或取消操作
          onOk() {
            deleteLmData(record.userId)
          },
        });*/
      }
      /*else {
        deleteLmData(record.userId)
      }*/
    };
    const deleteLmData = (userId) => {
      $api.deleteLmData(userId).then(() => {
        emit("update");
        message.success("删除成功");
      });
    };

    //是否为当前年、当前季度
    const isCurrentTime = (record) => {
      // 年和季度为空的数据也在当前年当前季度的结果中
      if(!record.year && !record.quarter) {
        return true
      }else {
        return record.year === currentQuarter.value.year && record.quarter === currentQuarter.value.quarter
      }
    };

    //是否为LM
    const isOnJobLm = (record) => {
      return record.staffStatus === '0'
    };

    //储备人员是否为在职人员
    const isOnJobPerson = (record) => {
      return record.jobStatus === '1'
    };

    watch(
      () => props.data.total,
      (val) => {
        pagination.total = val;
      },
      { deep: true }
    );
    watch(
      () => props.data.pageable,
      (val) => {
        pagination.current = val.page + 1;
        pagination.pageSize = val.size;
      },
      { deep: true }
    );
    return {
      rowData,
      userDataList,
      infoCollapse,
      columns,
      showHistoryLayer,
      showUpdateDrawer,
      showInfoDrawer,
      handleUpdateClick,
      handleInfoClick,
      handleDeleteClick,
      pagination,
      handleLmDeleteClick,
      currentQuarter,
      isOnJobLm,
      isCurrentTime,
      isOnJobPerson,
      modelValue,
      content,
      showIconCom,
      footer,
      handleOk,
      modelValue1,
      content1,
      showIconCom1,
      handleOk1,
    };
  },
});
</script>

<style scoped lang="less">
.collapse-cell {
  position: relative;
}

.collapse-btn {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #d0e2f3;
  color: #3182ce;
  position: absolute;
  left: -28px;
}
.flex-box{
  display: flex;
  .icon-btn {
    display: flex;
    width: 24px;
    height: 24px;
    :deep(svg) {
      width: 100%;
      height: 100%;
    }
  }
}
.disabled{
  cursor: auto;
  .anticon{
    color: #999 !important;
    svg{
      color: #999 !important;
    }
  }
}
.font-color{
  color:#4d4d4d;
}
.ant-table-striped {
  min-height: 25vw;
}
</style>
