<template>
  <!--  健康度看板 -->
  <div class="health-kanban">
    <div class="kanban">
      <div class="left">
        <Card class="user">
          <template #center>
            <a-avatar
              v-if="$store.state.app.portrait"
              style="margin-top: -60px"
              :size="120"
              :src="$store.state.app.portrait"
            />
            <img
              v-else
              class="profileImg"
              src="@/assets/menu/noImage.svg"
            />
          </template>
          <div class="name">
            {{ person.data.fullName }}
          </div>
          <div class="info">
            <div class="label">
              <span style="float: right;">:</span>
              <span class="labelContent">职务</span>
            </div>
            <div class="infoValue">{{ person.data.roleKey }}</div>
          </div>
          <div
            class="info"
            v-auth="['system:leadership:hierarchyInfo:cooAndTeamLeader']"
            v-show="person.data.hierarchy"
          >
            <div class="label">
              <span style="float: right;">:</span>
              <span class="labelContent">组织层级</span>
            </div>
            <div class="infoValue">{{ person.data.hierarchy }}</div>
          </div>
        </Card>
        <a-button
          class="button"
          type="primary"
          size="large"
          @click="showDrawer = true"
          v-auth="['system:leadershipManagement:showDrawer']"
          >查看组织架构
        </a-button>
      </div>
      <div class="center">
        <Card title="储备管理">
          <div
            class="row"
            v-for="{ key, title, color } in belongs.config"
            :key="key"
          >
            <span class="circle" :style="`color: ${color};font-size: 20px;`"
              >●</span
            >
            <span class="key">{{ title }}</span>
            <span
              :class="{
                link: true,
                underline: belongs.data?.total > 0,
              }"
              @click="handleClick()"
              >{{ belongs.data?.total }}人</span
            >
          </div>
          <div class="leadership-card-container">
            <CardTitle size="small">领导力健康度状态
              <span class="year-and-quarter-title" v-if="currentQuarter.year && currentQuarter.quarter">
                ({{currentQuarter.year + '/'+ currentQuarter.quarter}})
              </span>
            </CardTitle>
            <div class="score-comment-wrap">
              <div class="score-comment" v-for="{ title, key, filter } in evaluations.config" :key="key">
                {{ title }}
                <span class="content" :class="{
                  link: true,
                  underline: Number(evaluations.data[key]) > 0,
                  }" @click="handleClick(filter)"
                >{{ evaluations.data[key] }}人</span>
              </div>
            </div>
          </div>
          <div>
            <div
                class="row"
                v-for="{ key, title, color, filter } in lights.config"
                :key="key"
            >
            <span class="circle" :style="`color: ${color};font-size: 20px;`"
            >●</span
            >
              <span class="key">{{ title }}：</span>
              <span
                  :class="{
                link: true,
                underline: lights.data[key] > 0,
              }"
                  @click="handleClick(filter)"
              >{{ lights.data[key] }}人</span
              >
            </div>
          </div>
        </Card>
      </div>
      <div class="right">
        <Card>
          <CardTitle size="small">团队领导力健康度雷达
            <span class="year-and-quarter-title" v-if="currentQuarter.year && currentQuarter.quarter">
            ({{currentQuarter.year + '/'+ currentQuarter.quarter}})</span>
          </CardTitle>
          <div class="radarChart">
            <LeadershipWeightRadarChart
                :data="radar.data"
                v-loading="radar.loading"
                :setVal="false"
            />
          </div>
        </Card>
      </div>
    </div>
    <CardTitle title="团队各层级领导力健康度明细" size="large" />
    <a-table
      class="table"
      :rowKey="(record, index)=>index"
      :scroll="{ y: 300 }"
      :pagination="false"
      :dataSource="hierarchy.results"
      :columns="hierarchy.columns"
    />
    <div class="tip-warning">
      LM信息来自于MyConnect，请确保MyConnect中信息及时更新，否则会影响LM人数汇总。
    </div>
    <OrganizationStructureDrawer
      v-if="showDrawer"
      v-model:visible="showDrawer"
      :person-data="person.data"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import Card from "@/components/Card.vue";
import CardTitle from "@/components/CardTitle.vue";
import LeadershipWeightRadarChart from "./components/LeadershipWeightRadarChart.vue";
import OrganizationStructureDrawer from "./components/OrganizationStructureDrawer.vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from "ant-design-vue";

export default defineComponent({
  name: "HealthKanban",
  components: {
    Card,
    CardTitle,
    LeadershipWeightRadarChart,
    OrganizationStructureDrawer,
  },
  props: {
    orgs: String,
    filter: Object,
    switchTab: Function,
    currentQuarter: Object
  },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const person = reactive({
      data: {},
    });
    const belongs = reactive({
      data: {},
      config: [
        {
          title: "LM Pool",
          key: "LM_Pool",
          color: "#bcb1ff",
        },
      ],
    });
    const evaluations = reactive({
      data: {},
      config: [
        {
          title: "总人数",
          key: "totalLm",
          filter: {
            year: props.currentQuarter.year,
            quarter: props.currentQuarter.quarter,
            staffStatus:'0',
            evaluationStatus: '',
          },
        },
        {
          title: "已评价",
          key: "markedLm",
          filter: {
            year: props.currentQuarter.year,
            quarter: props.currentQuarter.quarter,
            staffStatus: '0',
            evaluationStatus: '1',
          },
        },
        {
          title: "待评价",
          key: "unmarkedLm",
          filter: {
            year: props.currentQuarter.year,
            quarter: props.currentQuarter.quarter,
            staffStatus: '0',
            evaluationStatus: '0',
          },
        },
      ],
    })
    const lights = reactive({
      data: {},
      config: [
        {
          title: "领导力健康度待提升",
          key: "RED",
          color: "#dc5e4f",
          filter: { health: ["red"] },
        },
        {
          title: "领导力健康度一般",
          key: "YELLOW",
          color: "#fdcd46",
          filter: { health: ["yellow"] },
        },
        {
          title: "领导力健康度优秀",
          key: "GREEN",
          color: "#58b99e",
          filter: { health: ["green"] },
        },
        {
          title: "Attrition Risk(H)",
          key: "AttritionRisk",
          color: "#ffb18f",
          filter: { attritionRisk: ["1"] },
        },
      ],
    });
    const radar = reactive({
      loading: true,
      data: [],
    });

    const handleClick = (filter) => {
      if(filter && filter.hierarchy) {
        filter.hierarchy = getHierarchyValue(filter.hierarchy)
      }
      props.switchTab(filter, filter ? "HealthDetail" : "PoolManagement");
    };

    const numberRender =
      (hierarchy) =>
      ({ text, record: { title, health } }) => {
        let filter = { hierarchy };
        if (health) filter.health = [health];
        if (title === 'LM' || title ===  'Red Amber LM' || title ===  'Green Performing LM') {
          return (
            <span
              class={`link ${text > 0 ? "underline" : ""}`}
              onClick={() => handleClick(filter)}
            >
              {text}
            </span>
          );
        } else {
          return text >= 0 ? text : "/";
        }
      };

    // 获取团队各层级领导力健康度明细层级值
    const getHierarchyValue = (label) => {
      if(label.indexOf('-') !== -1) {
        const index = parseInt(label.slice(2)) + 1
        return index + ''
      }else {
        return '1'
      }
    };

    const hierarchy = reactive({
      results: [],
      columns: [
        {
          title: "By Organization Layer",
          dataIndex: "title",
          key: "title",
          width: 350,
          customRender: ({ text, record }) => {
            if (record.color) {
              return (
                  <span>
                    <span
                        style={`color: ${record.color};float: left;font-size: 20px;`}
                    >
                      ●
                    </span>
                    <span>{text}</span>
                  </span>
              );
            } else {
              return <span>{text}</span>;
            }
          },
        },
      ],
    });

    const showDrawer = ref(false);

    const getPerson = () => {
      $api.getPerson().then((res) => {
        person.data = res.data;
      });
    };

    const getBelongs = () => {
      $api.getBelongs(props.orgs).then((res) => {
        belongs.data = res.data;
      });
    };

    const getLights = () => {
      $api.getLights(props.orgs).then((res) => {
        lights.data = res.data;
      });
    };

    const getRadar = () => {
      // @todo loading 待确定
      radar.loading = true;
      const params = {
        costCtrList: props.orgs.split(","),
        year : props.currentQuarter.year,
        quarter: props.currentQuarter.quarter
      }
      // getRadar
      $api
        .getDetailRadarData(params)
        .then((res) => {
          if (res.msg) {
            message.warning(res.msg);
          }
          radar.data = res.data;
        })
        .finally(() => {
          radar.loading = false;
        });
    };

    const getHierarchy = () => {
      $api.getNewHierarchy(props.orgs).then((res) => {
        hierarchy.results.splice(0);
        hierarchy.columns.splice(1)
        res.data.headers && res.data.headers.forEach((item) => {
          hierarchy.columns.push({
            title: item,
            dataIndex: handleFiled(item),
            key: handleFiled(item),
            customRender: numberRender(item),
          })
        })
        hierarchy.results = res.data.result ? res.data.result.map((item)=>{
          if(item.title === "Red Amber LM") {
            Object.assign(item,{color: "#DC5E4F",health: "red"})
          }else if(item.title === "Green Performing LM") {
            Object.assign(item,{color: "#58B99E",health: "green"})
          }
          return item
        }) : []
      });
    };

    const handleFiled = (val) => {
      return val.replace('-','_')
    };

    const scores = reactive({
      totalLm: 0,
      markedLm: 0,
      unmarkedLm: 0,
    });

    const getScores = () => {
      $api.getScores(props.orgs).then((res) => {
        scores.totalLm = res.data.total_lm;
        scores.markedLm = res.data.marked_lm;
        scores.unmarkedLm = res.data.unmarked_lm;
        evaluations.data = scores
      });
    };

    watch(
      () => props.orgs,
      () => {
        getBelongs();
        getLights();
        getHierarchy();
        getScores();
        getRadar();
      }
    );

    onMounted(() => {
      emit("update:filter", {});
      getPerson();
      getBelongs();
      getLights();
      getRadar();
      getHierarchy();
      getScores();
    });

    return {
      showDrawer,
      person,
      belongs,
      lights,
      radar,
      hierarchy,
      handleClick,
      scores,
      evaluations,
    };
  },
});
</script>

<style scoped lang="less">
.health-kanban {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  .kanban {
    display: flex;
    margin-bottom: 24px;
    flex-direction: row;
    height: 360px;

    .leadership-card-container{
      margin: 20px 0 0 0;
      padding-top: 20px;
      border-top: rgb(240 240 240) solid 2px;
    }

    .year-and-quarter-title {
      color: #2d3748;
      font-size: 17px;
      font-family: inherit;
    }

    .left {
      width: 25%;
      height: 100%;
      position: relative;

      .user {
        padding-top: 0;
        margin-top: 65px;
        height: 62%;
        text-align: center;

        :deep(.header) {
          justify-content: center;
        }

        .profileImg {
          width: 100%;
          height: 100%;
          margin-top: -50%;
        }

        .name {
          margin-top: 5%;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
        }

        .info {
          margin-top: 16px;
          display: flex;

          .label {
            width: 50%;
            text-align: justify;
            text-justify:distribute-all-lines; // 兼容ie浏览器
            text-align-last: justify;

            .labelContent {
              display: inline-block;
              width: 43%;
              white-space: nowrap;
              float: right;
            }
          }

          .infoValue {
            margin-left: 10px;
          }
        }
      }

      .button {
        width: 100%;
        height: 47px;
        position: absolute;
        bottom: 0;
      }
    }

    .center {
      margin: 0 24px;
      width: 25%;
      height: 100%;
      //flex-direction: column;
      justify-content: space-between;

      .row {
        margin-top: 16px;
        display: flex;
        align-items: center;

        &:first-child {
          margin-top: 24px;
        }

        .circle {
          font-size: 17px;
          margin: 0 8px 0 0;
        }

        .key {
          flex: 1;
          color: #505050;
        }
      }
    }

    .row:first-child {
      margin-top: 10px !important;
    }

    .right {
      width: 50%;
      flex: 1;

      .radarChart {
        height: 93%;
      }
    }

    :deep(.card) {
      height: 100%;
    }
  }

  .table {
    margin-top: 24px;

    :deep(.ant-table-row) {
      &:nth-child(4),
      &:nth-child(5) {
        background-color: #f7fafc;
      }
    }
  }

  .tip-warning {
    margin-top: 10px;
    line-height: 20px;
    color: #f59723;
  }

  .score-comment-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space:nowrap;
    margin-top: 10px;
    line-height: 30px;
    font-size: 16px;

    .content {
      padding-left: 5px;
    }
  }
}
</style>
