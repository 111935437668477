<template>
  <!--  储备管理 -->
  <div class="pool-management" v-if="!showHistoryLayer" id="pool-content">
    <Filter
      ref="filterRef"
      v-model:params="state.params"
      @search="handleSearch"
      @reset="handleReset"
      :secondMenu="'reserve'"
    >
      <template #operationBtns>
        <div class="buttons-wrap right">
          <Tooltip title="导出">
            <a-button class="" type="primary" ghost @click="handleExportData" v-auth="['system:leadershipManagement:exportPool']">
              <ExportOutlined />
            </a-button>
          </Tooltip>
          <Tooltip title="新增">
            <a-button class="ml12" type="primary" ghost @click="handleAddData" v-auth="['system:leadership:action']">
              <PlusOutlined />
            </a-button>
          </Tooltip>
        </div>
      </template>
    </Filter>
    <HealthDetailTable
      deleteAction
      :params="state.params"
      :data="data.page"
      @update="handleSearch"
      @getHistoryPage="getHistoryPage"
    />
    <AddUserModal
      v-if="showAddModel"
      :orgs="orgs"
      v-model:visible="showAddModel"
      @update="handleSearch"
    />
  </div>
  <!--  历史记录 -->
  <div class="pool-management" v-if="showHistoryLayer" :style="{height: historyHeight}">
    <HistoryLayer
        @return="showHistoryLayer = false"
        :data="rowData"
    />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, watch, nextTick, inject, onMounted, onUnmounted } from "vue";
import Filter from "./components/Filter.vue";
import HealthDetailTable from "./components/HealthDetailTable.vue";
import AddUserModal from "./components/AddUserModal.vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { cloneDeep } from "lodash";
import { downloadFile } from "@/utils/common";
import HistoryLayer from "./components/HistoryLayer.vue";
import { ExportOutlined, PlusOutlined } from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'

export default defineComponent({
  name: "PoolManagement",
  components: {
    Filter,
    AddUserModal,
    HealthDetailTable,
    HistoryLayer,
    ExportOutlined,
    PlusOutlined,
    Tooltip
  },
  props: {
    orgs: String,
    filter: Object,
    switchTab: Function,
    currentQuarter: Object,
  },
  emits:["getHistoryHeight"],
  setup(props,{ emit }) {
    const { $api } = useGlobalPropertyHook();
    const filterRef = ref(null);
    const showAddModel = ref(false);
    const data = ref({});

    const baseParams = {
      isPool: "1",
      costCtrList: props.orgs.split(","),
      pageIndex: 1,
      pageSize: 10,
      fullName: "",
      health: [],
      attritionRisk: [],
      hierarchy: undefined,
      orgThreeTwo: [],
      orgFourTwo: [],
      onShore: [],
      staffStatus: '0',
      grade: [],
      motivationList: [],
      jobStatus: 1,
    };
    const state = reactive({
      params: cloneDeep(baseParams),
    });
    const handleSearch = ({ pageIndex = 1, pageSize } = {}) => {
      if (pageIndex) state.params.pageIndex = pageIndex;
      if (pageSize) state.params.pageSize = pageSize;
      $api.getReserveDetailData(state.params).then((res) => {
        data.value = res.data;
      });
    };
    const handleReset = () => {
      state.params = cloneDeep(baseParams);
      handleSearch();
    };
    const handleAddData = () => {
      showAddModel.value = true;
    };
    const handleExportData = () => {
      $api.exportReserveDetailData(state.params).then((res) => {
        downloadFile(res.file, "储备管理.xls");
      });
    };

    const rowData = ref({})
    const showHistoryLayer = ref(false)
    const getHistoryPage = (data, isShow) => {
      rowData.value = data
      showHistoryLayer.value = isShow
    }
    const historyHeight = ref(inject('historyHeight').value.value)
    const getSize = () => {
      emit("getHistoryHeight")
    }
    onMounted(() => {
      getSize()
    })
    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });
    watch(
      () => props.orgs,
      () => {
        baseParams.costCtrList = props.orgs.split(",");
        nextTick(() => {
          filterRef.value?.handleReset();
        });
      },
      { immediate: true }
    );
    return {
      state,
      data,
      showAddModel,
      filterRef,
      handleSearch,
      handleReset,
      handleAddData,
      handleExportData,
      rowData,
      showHistoryLayer,
      getHistoryPage,
      historyHeight,
      getSize
    };
  },
});
</script>
<style scoped lang="less">
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
:deep(.filter-layout){
  gap: 15px;
}
:deep(.rms-filter-btn-container){
  width: 170px;
}
</style>
