<template>
  <div class="user-card">
    <a-avatar v-if="avatar" :src="avatar" :size="54" />
    <a-avatar v-else style="background: #546fe5" :size="54">{{
      user.fullName
    }}</a-avatar>
    <div class="ml12">
      <div>
        <span class="name">{{ user.fullName }}</span>
        <ManOutlined
          class="ml16"
          v-if="user.genderKey === 'Male'"
          style="font-size: 22px; color: #12abdb"
        />
        <WomanOutlined
          class="ml16"
          v-if="user.genderKey === 'Female'"
          style="font-size: 22px; color: #ee908a"
        />
      </div>
      <div class="user-id">
        <span
          >员工ID:<span class="link">{{ userInfo.idNumber }}</span></span
        >
        <span
          >职级: <span class="link">{{ userInfo.psGroup }}</span></span
        >
        <!-- <span v-if="isPoolTab" >直接下属人数: <span class="link">{{ user.underNumber }}</span></span>-->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { ManOutlined, WomanOutlined } from "@ant-design/icons-vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";

export default defineComponent({
  components: { ManOutlined, WomanOutlined },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    isPoolTab: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { $api } = useGlobalPropertyHook();
    const avatar = ref(null);
    watch(
      () => props.user.userId,
      (val) => {
        $api.getPhoto(val).then((res) => {
          avatar.value = res.data;
        });
      },
      { immediate: true }
    );
    return {
      avatar,
    };
  },
});
</script>

<style lang="less" scoped>
.user-card {
  display: flex;
  padding: 0 24px;
  align-items: center;
  .name {
    font-size: 24px;
    line-height: 34px;
    color: #505050;
  }
  .user-id {
    margin-top: 4px;
    font-size: 16px;
    line-height: 22px;
    color: #2d3748;
    .link {
      padding-right: 20px;
    }
  }
}
</style>
