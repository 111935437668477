// 总计列单独规则
export const totalLeadershipRender = ({ text, record }) => {    //   //是否后端控制颜色和评分，待定

  //后端控制评分，按返回颜色单词判断展示十六进制颜色；
  let color = "";
  let num = isNaN(Number(text || null)) ? 0 : Number(text);
  if (num === 0) {
    return <span></span>;
  } else {
    //总计列展示逻辑
    if (record.health == "green") {
      color = "#58B99E"; //绿色
    } else if (record.health == "yellow") {
      color = "#FDCD46"; //黄色
    } else if (record.health == "red") {
      color = "#DC5E4F"; //红色
    }
    return (
      <span>
        <span style={`color: ${color};margin-right: 8px;font-size: 20px;`}>
          ■
        </span>
        <span>{num}</span>
      </span>
    );
  }
};
// 表格常规数据展示列规则
export const leadershipRender = ({ text }) => {
  let color = "";
  let num = isNaN(Number(text || null)) ? 0 : Number(text);
  if (num === 0) {
    return <span style={`margin-right: 8px;font-size: 20px;`}></span>;
  } else {
    if (num <= 2) {
      color = "#58B99E";
    } else if (num > 2 && num < 4) {
      color = "#FDCD46";
    } else if (num >= 4) {
      color = "#DC5E4F";
    }
    return (
      <span>
        <span style={`color: ${color};margin-right: 8px;font-size: 20px;`}>
          ●
        </span>
        <span>{num}</span>
      </span>
    );
  }
};
// 提示渲染函数
export const tooltipRender = ({ text }) => {
  return (
    <ATooltip
      placement="topLeft"
      overlayClassName="table-tooltip"
      color="#fff"
      title={text}
    >
      {text}
    </ATooltip>
  );
};
