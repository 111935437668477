<template>
  <div ref="updateDrawerRef">
    <a-drawer
        :getContainer="()=>$refs.updateDrawerRef"
        title="LM领导力健康度明细更新"
        :visible="visible"
        width="40.6vw"
        @update:visible="(val) => $emit('update:visible', val)"
    >
      <UserCard :user="data" :userInfo="userInfo"/>
      <div class="buttons">
        <div>
          <a-button type="primary" @click="$emit('userInfo')">个人信息</a-button>
          <!-- <span class="grade-span">职级: <span>{{ form.grade }}</span></span> -->
        </div>
      </div>
      <div class="form">
        <a-form
            class="mt24 drawer-form"
            ref="formRef"
            layout="vertical"
            :model="form"
            :rules="rules"
        >
          <a-form-item label="识别动机" name="motivation">
            <SelectWithAll
                allowClear
                mode="multiple"
                :maxTagCount="1"
                v-model:value="form.motivation"
            >
              <a-select-option
                  v-for="(item, index) in motivationSelect"
                  :key="index"
                  :value="item"
              >{{ item }}</a-select-option
              >
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="新LM" v-if="isPoolTab" name="isNew">
            <a-select
                allowClear
                v-model:value="form.isNew"
                :options="enumList.tagOps"
            >
            </a-select>
          </a-form-item>
          <a-form-item label="是否关键人才" v-if="isPoolTab" name="isKeyTalent">
            <a-select
                allowClear
                v-model:value="form.isKeyTalent"
                :options="enumList.tagOps"
            >
              <a-select-option
                  v-for="(item, index) in motivationSelect"
                  :key="index"
                  :value="item"
              >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="动机备注" name="motivationRemarks">
            <a-textarea
                allowClear
                v-model:value="form.motivationRemarks"
                :auto-size="{ minRows: 2, maxRows: 5 }"
                showCount
                :maxlength="200"
            />
          </a-form-item>
          <a-form-item label="领导意愿" name="willingness">
            <a-input-number
                allowClear
                :min="1"
                :max="5"
                v-model:value="form.willingness"
            />
          </a-form-item>
          <a-form-item label="以人为本" name="humanized">
            <a-input-number
                allowClear
                :min="1"
                :max="5"
                v-model:value="form.humanized"
            />
          </a-form-item>
          <a-form-item label="有效沟通" name="communication">
            <a-input-number
                allowClear
                :min="1"
                :max="5"
                v-model:value="form.communication"
            />
          </a-form-item>
          <a-form-item label="协作" name="collaboration">
            <a-input-number
                allowClear
                :min="1"
                :max="5"
                v-model:value="form.collaboration"
            />
          </a-form-item>
          <a-form-item label="领导技能" name="skill">
            <a-input-number
                allowClear
                :min="1"
                :max="5"
                v-model:value="form.skill"
            />
          </a-form-item>
          <a-form-item label="领导精力" name="energy">
            <a-input-number
                allowClear
                :min="1"
                :max="5"
                v-model:value="form.energy"
            />
          </a-form-item>
          <a-form-item label="敬业投入" name="inputs">
            <a-input-number
                allowClear
                :min="1"
                :max="5"
                v-model:value="form.inputs"
            />
          </a-form-item>
          <a-form-item label="DISC" name="disc">
            <a-textarea
                allowClear
                v-model:value="form.disc"
                :auto-size="{ minRows: 1, maxRows: 1 }"
                showCount
                :maxlength="5"
            />
          </a-form-item>
          <a-form-item label="最近沟通时间" name="communicationTime">
            <a-date-picker
                placeholder=""
                valueFormat="YYYY-MM-DD"
                :disabledDate="disabledDate"
                v-model:value="form.communicationTime"
            />
          </a-form-item>
          <a-form-item label="备注" name="remarks">
            <a-textarea
                allowClear
                v-model:value="form.remarks"
                :auto-size="{ minRows: 2, maxRows: 5 }"
                showCount
                :maxlength="200"
            />
          </a-form-item>
          <a-form-item label="行动" name="action">
            <a-textarea
                allowClear
                v-model:value="form.action"
                :auto-size="{ minRows: 2, maxRows: 5 }"
                showCount
                :maxlength="200"
            />
          </a-form-item>
        </a-form>
      </div>
      <div class="bottom-btn">
      <span>
        <a-button type="primary" ghost @click="$emit('update:visible', false)"
        >取消</a-button
        >
        <a-button type="primary" class="ml12" @click="handleSave"
        >保存</a-button
        >
      </span>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { defineComponent, ref, inject, onMounted } from "vue";
import UserCard from "./UserCard.vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from "ant-design-vue";
import { enumList } from "@/api/enumStatic";
import moment from "moment";
export default defineComponent({
  components: { UserCard },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({
        communicationTime: null,
      }),
    },
    isPoolTab: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const showDrawer = ref(false);
    const formRef = ref(null);
    const form = ref({});
    Object.assign(form.value, props.data);
    for (let key in form.value) {
      if (form.value[key] === 0 && key !== "isNew" && key !== "isKeyTalent") {
        form.value[key] = null;
      }
    }
    form.value.motivation = form.value.motivation
      ? form.value.motivation.split(",")
      : [];
    // 初始化处理传入的form表单信息的关键人才和新LM字段为null的情况置为否
    // form.value.isKeyTalent = props.data.isKeyTalent;
    // form.value.isNew = props.data.isNew;
    const handleSave = async () => {
      await formRef.value.validate();
      $api
        .updateLeadershipDetailData({
          ...form.value,
          motivation: form.value.motivation.join(","),
          isPoolTab: props.isPoolTab
        })
        .then(() => {
          message.success("更新成功");
          emit("save");
          emit("update:visible", false);
        });
    };
    const numberRule = [
      {
        required: true,
        trigger: "change",
        validator: async (rule, value) => {
          if (isNaN(value) || value < 1 || value > 5 || value % 1 !== 0) {
            return Promise.reject("请输入1~5之间的整数");
          }
        },
      },
    ];
    const disabledDate = (current) => current > moment();
    // 获取历史人员的最新信息（职级、id）
    const userInfo = ref({})
    const getUserInfo = () => {
      $api.getHistoryUserInfo(props.data.userId).then((res) => {
        userInfo.value = res.data
      })
    };
    onMounted(() => {
      getUserInfo();
    });
    return {
      motivationSelect: inject("motivationSelect"),
      showDrawer,
      formRef,
      form,
      rules: {
        willingness: numberRule,
        humanized: numberRule,
        communication: numberRule,
        collaboration: numberRule,
        skill: numberRule,
        energy: numberRule,
        inputs: numberRule,
        motivation: [
          {
            required: true,
            trigger: "change",
            validator: async (rule, value) => {
              if (value?.length === 0) {
                return Promise.reject("识别动机不能为空");
              }
            },
          },
        ],
        isKeyTalent: [
          {
            required: true,
            trigger: "change",
            validator: async (rule, value) => {
              if (!value && value!== 0) {
                return Promise.reject("是否为关键人才不能为空");
              }
            },
          },
        ],
        isNew: [
          {
            required: true,
            trigger: "change",
            validator: async (rule, value) => {
              if (!value && value!== 0) {
                return Promise.reject("是否为新LM不能为空");
              }
            },
          },
        ],
        communicationTime: [
          {
            required: true,
            trigger: "change",
            message: "最近沟通时间不能为空",
          },
        ],
      },
      handleSave,
      disabledDate,
      enumList,
      userInfo,
      getUserInfo
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@/style/drawerForm.less";

.ant-drawer-body {
  .buttons {
    margin-top: 28px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    .grade-span {
      display: inline-block;
      padding-left: 20px;
      > span {
        color: #3182ce;
      }
    }
  }
}

.form {
  width: 100%;
  max-height: 72vh;
  margin-top: 20px;
  #communicationTime {
    width: 100%;
  }
}
</style>

